#EarnWindow {
    justify-content: space-between;
}

.ScrollBorder{
    height: 76%;
    width: 90%;
    display: flex;
    justify-content: center;
}

.EarnSkrollMenu{
    height: 96%;
    width: 95%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    /*overflow: scroll;*/
}

.TaskBorder{
    height: 30%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2.5vh;
}


.Task{
    background: #2D2D2D;
    height: 100%;
    width: 42%;
    border-radius: 2vh;
    box-shadow:inset 0 0 0 0.2vh #838282,
                     2px 2px 3px rgba(0,0,0,50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.TaskText{
    width: 100%;
    height: 38%;
    display: flex;
    align-items: center;
    justify-content: end;
    flex-direction: column;
}

.TaskText p{
    font: 2.4vh Muller;
    margin: 0.2vh;
}

.TaskIMG{
    width: 100%;
    height: 62%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.TaskIMG img{
    margin-bottom: 1vh;
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,30%));
}

#BigTask{
    width: 50%;
}

#BigTask p {
    font: 1.8vh Muller;
    margin: 0;
}

#BigTask img{
    margin: 1vh;
}

#BigTask #littleEarn_p{
    font: 1.5vh MullerLight !important;
}