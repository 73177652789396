#LootWindow{
  justify-content: start;
}

#LootWindow p{
  margin: 0;
}

.checkbox {
    width: 55vw;
    height: 4.5vh;
    background:#2D2D2D;
    border-radius: 3vh;
    position: relative;
    overflow: hidden;
  }
  
  #checkbox_toggle {
    display: none;
  }
  
  .checkbox .toggle {
    width: 27vw;
    height: 3.8vh;
    position: absolute;
    border-radius: 2.5vh;
    left: 0.5vh;
    top: 0.4vh;
    background: linear-gradient(40deg, #fbff09,#FF8C00 70%);
    transition: 0.4s;
    box-shadow: 0px 0px 3px rgb(255, 145, 0), 0px 0px 5px rgb(255, 174, 0);
  }
  
  .checkbox .slide {
    width: 53.5vw;
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  
  .checkbox .slide .text {
    margin-bottom: 2.8vh;
    font: 1.8vh MullerMedium;
    z-index: 100;
  }
  
  .check:checked + .checkbox .slide .toggle {
    transform: translateX(26.5vw);
  }
  
  .check:checked + .checkbox .slide #CraftTxt{
    color: #000;
    font-weight: bold;
  }

  .check:not(:checked) + .checkbox .slide #LootTxt{
    color: #000;
    font-weight: bold;
  }

.SWITCHBTN {
    width: 90vw;
    height: 6vh;
    margin-top: 3vh;
    margin-bottom:1vh ;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.SWITCHBTN button{
  background: #CC2C2C;
  color: white;
  height: 4.5vh;
  width: 35%;
  border-radius: 3vh;
  box-shadow: inset ( 0 0 0.6vh #5B5D38);
  font: 1.6vh MullerMedium;
  padding-top: 0.7vh;
}

.Loot_Wnd {
  width: 100%;
  max-height: 54vh;
  display: flex;
  justify-content: center;
}

.Loot_Scroll_Menu {
    height: 100%;
    width: 90%;
    overflow: scroll;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.Loot_Card {
    height: 21vh;
    width: 47.5%;
    background: #2D2D2D;
    border-radius: 2vh;
    box-shadow: inset 0 0 0 0.2vh #838282, 2px 2px 3px rgba(0,0,0,50%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4vw;
    overflow: hidden;
}

.Loot_Card img{
  height: 75%;
  filter: drop-shadow(0px 2px 3px rgba(0,0,0,50%));
}

#Rare_item_card {
  box-shadow: inset 0 0 0 0.5vh #3402FA, 2px 2px 3px rgba(0,0,0,50%);
}
#Rare_item_card img{
  filter: drop-shadow(0 0 1.5vh  orange);
}
#Epic_item_card img{
  filter: drop-shadow(0 0 1.5vh  orange);
}
#Epic_item_card {
  box-shadow: inset 0 0 0 0.5vh #771B9E, 2px 2px 3px rgba(0,0,0,50%);
}
#Basic_item_card img{
  height: 80%;
}