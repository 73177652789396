.Podsos
{ 
    height: 48%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative
}

.Podsos img{
    height: 95%; 
    width: auto;
}

.click-value {
    position: absolute;
    height: 6vh;
    width: 6vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 2vh;
    font-family: MullerLight;
    font-weight: bold;
    pointer-events: none;
    user-select: none;
}

.click-value p{
    z-index: 1;
    margin-top: 2.5vh;
    
}
#inktap{
    position: absolute;
    z-index: 0;
}

#inktap img{
    width: 100%;
}