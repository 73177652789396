.CraftWindow{
    width: 100%;
    height: 54vh;
    position: fixed;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    bottom: 0;
    color: white;
    background: #2D2D2D;
    font: 10vh Muller;
    z-index: 1000;
}