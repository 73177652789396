*{
    user-select: none;
}

.Ref_Earn_Shop_Window {
    width: 100%;
    height: 78%;
    position: fixed;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 3vh 3vh 0 0;
    bottom: 0;
    color: white;
    background: #151515;
    z-index: 1000;
}

.Ref_Earn_BoxBorder{
    width: 90%;
    height: 18%;
    border-radius: 2vh;
    box-shadow:inset 0 0 0 0.1vh #454545,
                    2px 2px 3px rgba(0,0,0,50%);
    background:#2D2D2D;
    position: relative;
    display: flex;
    flex-direction: row;
    top: 2vh;
}

.Ref_Earn_Box{
    width: 28%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2vh;
    box-shadow:inset 0 0 0 0.1vh #454545,
                    0 0 3px 0 #151515;
}

.Ref_Earn_Box img{
    filter: drop-shadow(2px 2px 3px rgba(0,0,0,50%));
}

.Ref_Earn_BoxTitle{
    width: 72%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.Ref_Earn_BoxUp{
    width: 100%;
    height: 35%;
    display: flex;
    align-items: end;
    justify-content: center;
    
}

.Ref_Earn_BoxUp p{
    margin: 0;
    font-size: 2vh;
    font-family: MullerMedium;
}

.Ref_Earn_BoxDown{
    width: 100%;
    height: 65%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.Ref_Earn_BoxLeft{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2vh;
    height: 100%;
}

.Ref_Earn_BoxLeft img{
    height: 60%;
}

.Ref_Earn_BoxRight{
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.Ref_Earn_BoxRight p{
    margin: 0;
    font-size: 2vh;
    font-family: Muller;
}

.Ref_Earn_Purple{color: #CF2395;}

.refFrandsBorder{
    border-radius: 2vh;
    box-shadow:inset 0 0 0 0.1vh #454545;
    background:#2D2D2D;
    height: 65%;
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 3.5vh;
}

.refFrendsInfo{
    height: 14%;
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.refFrendsInfo p{
    margin: 0;
    font-size: 2vh;
    font-family: MullerMedium;
}

.refFrendsMenu{
    height: 80%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: scroll;
    box-sizing: border-box;
}

.refFrends{
    height: 16%;
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 1.8vh; 
}

.refFrends img{
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,30%));
}

.refFrendsIcon{
    height: 100%;
    width: 22%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.refFrendsName{
    height: 100%;
    width: 78%;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;
}

.refFrendsName p{
    margin: 0;
    font-family: Muller;
    font-size: 2vh;
}

#Friends_rank{font-size: 1.5vh;}

#Beginner_rank{color: #F2FA82;}

#Advanced_rank{color: #F08B15;}

#Intermediate_rank{color: #FFB803;}

#Expert_rank{color: #E32121;}

#Master_rank{color: #A513FF;}

#Grandmaster_rank{color: #CD7F32;}


.refthripleBTN{
    position: absolute;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    height: 17%;
    width: 100%;
    bottom: 0;  
    background: #151515;
    box-shadow: 0 -2px 0 #f6ff82;
    border-radius: 3vh 3vh 0 0;
}

.refgo {
    width: 44vw;
    height: 7.4vh;
    border-radius: 1.5vh;
    box-shadow: 0 0 0 0.5vh #2D2D2D;
    background: #202020; 
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font: 3.2vh MullerMedium;
}

.refgo img{
    filter: drop-shadow(2px 2px 3px rgba(0,0,0,10%));
}

.refgo p{
    margin-top: 4.2vh;
    margin-right: 0.5vh;
}

#refgoCopy {
    width: 20vw;
    flex-direction: column;
    margin-right: 1vh;
}

#refgoCopy p{
    margin: 0;
    font: 2vh MullerMedium;
}

#refgoEx{
    width: 20vw;
    margin-left: 1vh;
    padding-top: 0.4vh;
}

#FrendAvatarInvite{
    border-radius: 50%;
}
