*{
  user-select: none;
}

body {
	margin: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
}

.App {
  margin: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  text-align: center;
  flex-direction: column;
  background: linear-gradient(#000000, #434343 20%);
  z-index: 0;
}

.info {
  width: 100%;
  height: 8%;
  display: flex;
  align-items: center;
  color: white;
}

.info p {
  margin-left: auto;
  font: 2.6vh MullerMedium;
  margin-top: 4.75vh;
  filter: drop-shadow(0 0 2px  black);
}

.info img{
  margin-top: 2vh;
  margin-left: 4vw;
  filter: drop-shadow(0 0 2px  black);
}

.logo{
  width: 100%;
  height: 14%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img{
  margin-bottom: 1.5vh;
  position: absolute;
  height: 90%;
}

.main {
  width: 100%;
  height: 78%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 3vh 3vh 0 0;
  background: #151515;
  z-index: 1;
}

.mainInfo{
  width: 100%;
  height: 20%;  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.BorderMainInfo{
  width: 95%;
  height: 88%;
  display: flex;
  align-items: center;
  justify-content: space-evenly; 
  border-radius: 3vh;
  border: 0.2vh;
  border-style: solid;
  border-color: #323232;
  box-shadow: 0 0 15px -2px black;
}

.tripleBox {
  width: 28%;
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center; 
  flex-direction: column; 
  position: relative;
  border-radius: 1.5vh;
  box-shadow: 0px 0px 10px -2px black; 
  color: white;
  background: #323232;
}

.tripleBox p{
  margin: 0.5vh;
  font: 2vh Muller;
  white-space: nowrap;
}

.tripleBox img{
  vertical-align: middle;
  margin: 0;
  margin-bottom: 0.2vh;
  height: 2.2vh;
  
}

.important {
  width: 100%;
  height: 22%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 0 0 1vh 1vh;
  box-sizing: border-box; 
  bottom: 0;
  color: #151515;
  background: #940473;
}

.important p{
  margin: 0;
  font: 2vh Muller;
  font-weight: bold;
}

.lower{
  width: 100%;
  height: 17%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3vh 3vh 0 0;
  box-shadow: 0 -2px 0 #f6ff82;
}

.lowerDown{
  width: 92%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
}

.BTN{
  height: 100%;
  width: 20vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.BTN p{
  color: white;
  margin: 0;
  font: 2.3vh Muller;
  margin-bottom: 1vh;
  font-weight: bold;
}

.BTNLOW {
  width: 100%;
  height: 7.4vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.5vh;
  box-shadow: 0 0 0 0.5vh #2D2D2D;
  background: #202020;
  margin-top: 1.2vh;
}

.BTNLOW img {
  height: 80%;
  width: auto;
  filter: drop-shadow(2px 2px 3px rgba(0,0,0,50%));
}

.Progress{
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.energy{
  width: 90%;
  height: 50%;
  display: flex;
  justify-content: left;
  align-items: center;
  font: 2.2vh MullerMedium;
  color: white;
}

.energy img{
  margin-right: 5px;
}

.userStatus{
  width: 90%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: start;
  font: 1.5vh Muller;
  margin-left: 5px;
  color: white;
}

.userStatus p {
  margin: 0;
}

.BackGround_Div {
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 78%;
  border-radius: 3vh 3vh 0 0;
  box-shadow: 0 -2px 0 #f6ff82,
                  0 0 4px black;
  background: #151515;
  z-index: 0;
}

#nonBold{font-family: MullerLight; color: lightgray;}

#odstup{margin-right: auto;}

#kalendar{margin-bottom: 1vh;}

#left_thriple{margin-left: 0.8vh;}

#right_thriple{margin-right: 0.8vh;}

#pngavatar{margin-right: 4vw; margin-top: 1.6vh; margin-left: 2vw;  height: 70%; border-radius: 50%;}

#CloseDebug{
  background: red;
  box-shadow: inset 0 0 0 0.3vh #5c1616;
  color: black;
  font-weight: bold;
  border-radius: 3vh;
  align-items: center;
  width: 4vh;
  height: 4vh;
  position: absolute;
  left: 1vw;
  bottom: 79vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 2vh MullerMedium;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.6s ease;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.6s ease;
}

@keyframes slide-down {
  from {transform: translateY(0);}
  to   {transform: translateY(100%);}
}

@keyframes slide-up {
  from {transform: translateY(100%);}
  to   {transform: translateY(0);}
}

@keyframes rotate-end {
  from {
        transform-style: preserve-3d;
        transform: rotateY(0deg);
        opacity: 1;}
  to   {
        transform-style: preserve-3d;
        transform: rotateY(-90deg);
        opacity: 0;}
}

@keyframes rotate-start {
  from {
        transform-style: preserve-3d;
        transform: rotateY(-90deg);
        opacity: 0;}
  to   {
        transform-style: preserve-3d;
        transform: rotateY(0deg);
        opacity: 1;}
}

.main { animation: rotate-start 0.3s;}

.main.closing { animation: rotate-end 0.3s;}

.Ref_Earn_Shop_Window { animation: rotate-start 0.3s;}

.Ref_Earn_Shop_Window.closing { animation: rotate-end 0.3s;}

@font-face {
  font-family: Muller;
  src: url(/src/fonts/MullerRegular.ttf) format('truetype');
}

@font-face {
  font-family: MullerLight;
  src: url(/src/fonts/MullerLight.ttf) format('truetype');
}

@font-face {
  font-family: MullerMedium;
  src: url(/src/fonts/MullerMedium.ttf) format('truetype');
}

button{
  border: none;
}

.referral-section{
  visibility: hidden;
  position: absolute;
}

