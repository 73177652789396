.loading-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0,0,0,1) 0%, rgba(42,63,78,1) 65%);
    display: flex;
    margin: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 9999;
  }

.ldnUpper {
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2; /* Higher z-index */
}
.ldnUpper img {width: 90%;}

.ldnLower {
    height: 70%;
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: center;
    position: relative;
    z-index: 2; /* Higher z-index */
}
.ldnLower img {height: 100%;}

.ink {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    z-index: 1; /* Lower z-index */
}
.ink img {filter: drop-shadow(2px 2px 3px rgba(0,0,0,50%));}

#ink1{
    align-items: start;
    justify-content: end;
}
#ink1 img{ height: 10%;}

#ink2{
    align-items: start;
    justify-content: start;
}
#ink2 img{height: 25%;}

#ink3{
    top: 35vh;
    justify-content: end;
}
#ink3 img{height: 15%;}

#ink4{
    top: 50vh;
    right: -70vw;
}
#ink4 img{height: 6%;}

#ink5{
    top: 12vh;
    right: -65vw;
}
#ink5 img{height: 12%;}

#ink6{
    top: 28vh;
    right: -51vw;
}
#ink6 img{height: 5%;}

#ink7{
    top: 38vh;
    left: 18vw;
}
#ink7 img{height: 10%;}

#ink8{
    top: 30vh;
    left: 10vw;
}
#ink8 img{height: 5%;}

.loader {
    width: 40vh;
    height: 40vh;
    border: 10px solid #3b032f2d;
    border-top-color: #570645;
    animation: spin013151 1s linear infinite;
    border-radius: 100%;
    position: absolute;
    z-index: 1;
  }
  
  @keyframes spin013151 {
    to {
      transform: rotate(360deg);
    }
  }